import { AbilityDefinitionModel } from "@/models/AbilityDefinitionModel";
import { AbilityHelper } from "@/helpers/AbilityHelper";
import { PermissionModel } from "@/models/PermissionModel";
import { AbilityDataModel } from "@/models/AbilityDataModel";

export const allPermission: PermissionModel[] = [
  // * Group MISC
  { action: "READ", target: "HELP_CENTER", commonAccess: true },
  { action: "CREATE", target: "HEADER_CONTACT_CS", commonAccess: true },
  { action: "READ", target: "HEADER_DOWNLOAD_MANUAL", commonAccess: true },

  /**
   * * PRODUCT Group
   * ? Not Shown in SmartChat
   */
  /**
   * * TRANSACTION Group
   * ? Not Shown in SmartChat
   */
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_TRANSACTION_BUTTON_CREATE",
    userPermissionIndex: 9,
  },
  /**
   * * WHATSAPP Group
   * ? Not Shown in SmartChat
   */
  /**
   * * VIBER Group
   * ? Not Shown in SmartChat
   */
  /**
   * * SOCIAL Group
   */
  /**
   * * CUSTOMER SERVICE Group
   */
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_INBOX",
    userPermissionIndex: 1,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_INBOX_CHANNEL_FILTER_BUTTON_CONNECT",
    commonAccess: true,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_HISTORY",
    userPermissionIndex: 2,
  },
  {
    action: "READ",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  {
    action: "CREATE",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  {
    action: "UPDATE",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  {
    action: "DELETE",
    target: "CUSTOMER_SERVICE_SETTING",
    userPermissionIndex: 0,
  },
  // ? Chat Settings Only shown in Neo KKT
  /**
   * * CALL CENTER Group
   * ? Only shown on NEO KKT
   */
  /**
   * * PERFORMANCE Group
   */
  { action: "READ", target: "CS_PERFORMANCE", userPermissionIndex: 10 },
  /**
   * * CRM Group
   * ? Not shown on SmartChat
   */
  /**
   * * CHAT CREDITS Group
   * ? Not shown on SmartChat
   */
  /**
   * * WALLETS Group
   * ? Not shown on SmartChat
   */
  /**
   * * SETTINGS Group
   */
  { action: "READ", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "CREATE", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "UPDATE", target: "SETTING_STORE", userPermissionIndex: 14 },
  { action: "DELETE", target: "SETTING_STORE", userPermissionIndex: 14 },
  {
    action: "READ",
    target: "SETTING_STORE_SMART_CHAT",
    userPermissionIndex: 14,
  },
  { action: "READ", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  { action: "CREATE", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  { action: "UPDATE", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  { action: "DELETE", target: "SETTING_ADMIN", userPermissionIndex: 15 },
  // ? User Features Access only open in NEO KKT
  { action: "READ", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  { action: "CREATE", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  { action: "UPDATE", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },
  { action: "DELETE", target: "SETTING_PLAN_PRICING", userPermissionIndex: 16 },

  /**
   * * ADVANCE_SEARCH
   */
  {
    action: "READ",
    target: "ADVANCE_SEARCH",
    clientPermissionIndex: 29,
  },
];

const adminNegativePermissions: PermissionModel[] = [];

const customerServicePermissions: PermissionModel[] = [
  { action: "READ", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "CREATE", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "UPDATE", target: "CUSTOMER_SERVICE_INBOX" },
  { action: "DELETE", target: "CUSTOMER_SERVICE_INBOX" },

  { action: "READ", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "CREATE", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "UPDATE", target: "CUSTOMER_SERVICE_HISTORY" },
  { action: "DELETE", target: "CUSTOMER_SERVICE_HISTORY" },

  { action: "CREATE", target: "CUSTOMER_SERVICE_TRANSACTION_BUTTON_CREATE" },
];

export default function defineAbilityFor(abilityModel: AbilityDefinitionModel) {
  const data: AbilityDataModel = {
    allPermissions: allPermission,
    adminNegativePermissions: adminNegativePermissions,
    customerServicePermissions: customerServicePermissions,
  };

  return AbilityHelper.getInstance().attachAbility(data, abilityModel);
}
